@import '../../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
  margin: 7px 0 15px 0 !important;
  @media (--viewportMedium) {
    margin-bottom: 0 !important;
    margin: 0px 0 30px 0  !important;
  }
}

.desktopInputRoot { 
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  display: none;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpBNEFDNzhCNTE5NTZFRjExOTJBNkIyMjU0MUMwODg3QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGNkJFMjAyMTYwNjExMUVGODRENEI5RTVBQUQ4QjZDQyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGNkJFMjAyMDYwNjExMUVGODRENEI5RTVBQUQ4QjZDQyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1LjEgV2luZG93cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBGRjE0Nzg5QzI1RUVGMTE4RkMzOTdFMThENTgxQjRBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE0QUM3OEI1MTk1NkVGMTE5MkE2QjIyNTQxQzA4ODdCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+LdCNHQAAAR9JREFUeNpi/P//PwOxILuqmwlI+QHxPRYiNXAAqTggLgZiNSAOYCGggQ9IJQJxCRBfAOIVQBwBxJuZcGhQAOIOIPMOEBsDsfvUtlJfIG0IxL1A9j8WNA36UOd4A/ESIDYCKnoClVMHUqZAHA7is0AFbYBUOdT0WUCsDNTwAc0hZUA8BSj+HcRhzKrsSgDS86GS54H4ORC/BuI3QLwYqPAi0GBJIPsKEKsC+e9ACpmAjAVAmgeIFYE4E4inA/EBkCIgjoEamAdyOkwT2EZc8Qi0BRSa9kCcC8T3gdgMqPEeTJ4JT2y8AmJRIE4D4p3ImuCBgwOA/CkFdWYguiQTAY0GQHwbaNs5UjS+gtJd2CRxagTa8hVIxQPxbmzyAAEGAKVnVyhIHupCAAAAAElFTkSuQmCC) no-repeat left center;

  @media (--viewportMedium) {
    margin-left: 0;
    display: flex;
  }
}

.fontInput {
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 5px 13px 15px 13px;
    margin: 0;
  }
}

.mobileInput {
  /* Font */
  composes: fontInput;

  /* Layout */
  height: 40px !important;
  border: 1px solid var(--colorGrey100);
  border-radius: 8px;
  padding: 0 0 0 10px !important;
  margin-right: 0;

  /* Borders */


  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);
  display: none;
}

.desktopInput {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  height: 30px;
  font-size: 14px;
  font-weight: normal;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) { 
    margin-bottom: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 43px;
  left: 0;
  /* searchContainer margin-top (94px) + mobileInput height (50px) = 144px */ 
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    min-height: unset;
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 99%;
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  max-width: 434px;
  @media (--viewportMedium) {
    width: 500px;
  }
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.searchAllignment{
  display: flex
}



.searchBox {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 510px;
  max-height: 72px;
  position: relative;
  color: var(--colorGrey);
  font-size: 12px;

  @media (--viewportSmall) {
    border-right: solid 1px var(--colorLight);
    flex-direction: row;
    display:  flex;
  }
  @media (--viewportLarge) {
    display: inline-flex;
    gap: 15px;
    padding: 0 15px;
    /* border-radius: 50px; */
  }
  &>div{
    padding: 0px 0;
    text-transform: none;
    @media (--viewportSmall) {
      padding: 3px 0 5px 0;
    width: 170px;
    }
    &:last-child{
      border: none;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 12px;
    padding: 0; 
    margin-bottom: 13px;
    font-weight: var(--fontWeightSemiBold);
    position: relative;
    top: 3px;
    color: var(--colorGrey);
    text-transform: none;
  }

  .formControl {
    border: none;
    padding: 0;
    font-size: 14px;
    height: 30px;
    border: 1px solid var(--colorGrey100);
    height: 40px;
    padding: 0 0 0 10px !important;
    @media (--viewportSmall) {
      border: none;
      padding: 0;
      font-size: 14px;
      height: 30px;
    }
  }

  .formLocation {
    .formControl {
      padding-left: 20px;
      /* background: url(img/current.png) no-repeat left center; */
    }
  }
}






.primaryButton {
  background: var(--bgBrandGradient);
  border-radius: 50px;
  color: var(--colorWhite);
  border: none;
  padding: 15px 30px;
  height: auto;
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
  @media (--viewportLarge) {
    padding: 10px 15px;
    font-size: 14px;
    width: auto;
    margin: 0 auto;
  }
  img {
    width: 16px;
    vertical-align: sub;
    margin-right: 10px;
  }
}

.locationLabel{
  color: var(--colorGrey);
  margin: 0;  
  font-weight: var(--fontWeightSemiBold);
  font-size: 12px;
  text-transform: uppercase;
  z-index: 1;
  @media (--viewportLarge) {
    position: absolute;
    left: 15px;
    top: 0;
  }
}