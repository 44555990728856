@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/custom.scss';

.wrapper {
    position: fixed;
    bottom: 50px;
    max-width: 345px;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 15px 25px 22px;
    transition: right 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    font-family: "Poppins", sans-serif;
    z-index: 99999;
  }
  
  .show {
    right: 20px;
  }
  
  .header {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  
  header i {
    color: #4070f4;
    font-size: 32px;
  }
  
  .h2 {
    color: var(--marketplaceColor);;
    font-weight: 500;
  }
  
  .data {
    margin-top: 16px;
    color: #333;
    font-size: 16px;
    font-weight: 400;
  }
  
  .data p {
    color: #333;
    font-size: 16px;
  }
  
  .data p a {
    color: #4070f4;
    text-decoration: none;
  }
  
  .data p a:hover {
    text-decoration: underline;
  }
  
  .buttons {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: flex-end;
    gap: 10px;
  }
  
  .acceptButton {
    
    composes: buttonPrimary from global;
    min-height: auto;
    padding: 14px 36px;
    width: auto;
  }
 

  .declineButton {
    background: #ffff;
  border-radius: 50px;
  color: black;
  border: none;
  padding: 15px 30px;
  height: auto;
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  cursor: pointer;
  transition: all .2s ease-in-out;

  @media (--viewportLarge) {
    padding: 11px 36px;
    font-size: 15px;
  }
  }
  
 
  
  #declineBtn {
    border: 2px solid var(--marketplaceColor);
    background-color: #fff;
    
  }

  