/* Rinu */
::selection {
  background-color: #393978;
  color: #fff;
}

*,
::after,
::before {
  box-sizing: border-box;
}


.container {
  margin: 0 auto;

  @media (min-width: 0) {
    max-width: 300px;
  }

  @media (--viewportSmall) {
    max-width: 550px;
  }

  @media (--viewportMedium) {
    max-width: 768px;
  }

  @media (--viewportLarge) {
    max-width: 1024px;
  }

  @media (--viewportLargeWithPaddings) {
    max-width: 1128px;
  }

  @media (min-width: 1400px) {
    max-width: 1400px;
  }

  @media (--viewportXLarge) {
    max-width: 1921px;
  }
}

.containerFluid {
  width: 100vw;

}

@media (min-width: 1200px) {
  .containerFluid {
    width: calc(100vw - 25px);
  }
}

.serviceCard {
  background: #fff;
  border-radius: 15px;
  max-width: 275px;
  overflow: hidden;
  padding: 10px;
  margin: 0 auto 30px auto;
  position: relative;
  transition: all .2s ease-in-out;

  @media (--viewportSmall) {
    padding: 25px;
    border-radius: 25px;
  }

  img {
    max-width: 100%;
    width: 100%;
    border-radius: 15px;
  }

  .shadow {
    position: absolute;
    width: calc(100% - 50px) !important;
    top: 65px;
    filter: blur(15px);
    left: 20px;

    @media (--viewportSmall) {
      top: 40px;
      left: auto;
    }
  }

  .image {
    position: relative;
  }

  h5 {
    font-size: 14px;
    color: var(--colorBlack);
    text-transform: uppercase;
    font-weight: var(--fontWeightBold);
    margin: 0;
    text-align: center;

    @media (--viewportSmall) {
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 26px;

    @media (--viewportSmall) {
      flex-direction: row;
    }

    .price {
      background: var(--colorBrand);
      font-size: 12px;
      color: #fff;
      padding: 10px;
      border-radius: 25px;
      line-height: 10px;
    }

    button {
      background: var(--colorGrey);
      color: #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      border: none;
      cursor: pointer;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: "";
      }
    }
  }

  &:hover {
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

@media only screen and (max-width: 550px) {
  .serviceCard {
    background: none;
    padding: 0;
    border-radius: 0;
    margin: 0;
    overflow: visible;

    &:after {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(49, 58, 74, 0.9) 100%);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    .shadow {
      left: -5px;
      top: 40px;
    }

    .footer {
      padding: 10px;
      border-radius: 30px;
      backdrop-filter: saturate(180%) blur(20px);
      background-color: #FFFFFF42;
      position: absolute;
      z-index: 999;
      bottom: 10px;
      width: calc(100% - 20px);
      left: 10px;
      border: solid 1px #ffffff47;

      h5 {
        color: #ffffff;
      }
    }
  }
}


.eventCard {
  background: #fff;
  padding: 8px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--boxShadowSectionLocationHover);
  transition: all .2s ease-in-out;
  text-align: center;
  margin-bottom: 15px;

  @media (--viewportSmall) {
    text-align: left;
  }

  a:hover {
    text-decoration: none;
  }

  .image {
    max-width: 100%;
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 16px;
    font-weight: var(--fontWeightBold);
    margin: 0;
    padding: 10px 0;
    color: var(--colorDarkGrey);
  }

  .footer {
    text-align: right;

    button {
      border: none;
      color: var(--colorGrey);
      cursor: pointer;
      font-size: 16px;
      padding: 5px 0;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: "";
      }
    }
  }

  &:hover {
    box-shadow: 0px 25px 30px -15px rgba(0, 0, 0, 0.35);
  }

}

.locCard {
  max-width: 180px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 25px 30px -15px rgb(0, 0, 0);
  transition: all .2s ease-in-out;

  @media (--viewportLarge) {
    max-width: 316px;
  }

  &:hover {
    box-shadow: 0px 35px 30px -15px rgb(0, 0, 0);
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.85) 100%);
  }

  img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 35px;
    z-index: 1;


    h5 {
      font-size: 14px;
      margin: 0 0 10px 0;
      position: relative;
      padding-bottom: 10px;
      text-align: left;

      @media (--viewportSmall) {
        font-size: 18px;
      }

      @media (--viewportLarge) {
        font-size: 26px;
        margin: 0 0 20px 0;
        padding-bottom: 25px;
      }

      &::after {
        position: absolute;
        width: 65%;
        height: 2px;
        background: #fff;
        left: 0;
        content: "";
        bottom: 0;
      }
    }

    .footer {
      display: flex;
      gap: 10px;
      line-height: 1em;
      text-align: left;

      span {
        background-color: #ffffff;
        color: #000;
        width: 57px !important;
        height: 33px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        outline: 1px solid rgba(255, 255, 255, 0.5);
        font-size: 14px;
        font-weight: var(--fontWeightBold);

        @media (--viewportLarge) {
          width: 40px;
          height: 40px;
          font-size: 16px;
          outline: 5px solid rgba(255, 255, 255, 0.5);
        }
      }

      a {
        color: #ffffff;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          content: "";
        }
      }
    }
  }
}

.brandButton {
  background: var(--bgBrandGradient);
  border-radius: 50px;
  color: var(--colorWhite);
  border: none;
  padding: 15px 30px;
  height: auto;
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 25px 30px -15px rgb(233, 83, 130);
  transition: all .2s ease-in-out;

  @media (--viewportLarge) {
    padding: 20px 40px;
    font-size: 16px;
  }

  &:hover {
    box-shadow: 0px 35px 30px -15px rgb(233, 83, 130);
  }
}

.VisibleDesktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.VisibleMobile {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.mobileContainer {
  padding: 15px;
}