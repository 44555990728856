@import '../../../../../styles/customMediaQueries.css';

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.priorityLink { 
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack); 
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
  .navIcon{    
    /* font-size: 25px; */
    margin-right: 5px;
  }
}

.highlight {
  color: var(--colorBlack);
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.priorityLinkLabel {
  font-size: 14px;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
}
.unVerifiedSpan{
  display: inline-flex;
  padding: 23px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
  align-items: center;
}