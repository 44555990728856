@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
    box-shadow: var(--boxShadow);
  }

  @media (--viewportLarge) {
    display: none;
  }

  .mobileLeft {
    display: flex;
    align-items: center;
  }

  .mobileRight {
    display: flex;
  }

  .mobiletopbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.anchorLess {
  border: 0px;
}

.logoLink {
  max-width: 130px;
}

.drawerContainer {
  justify-content: space-between;
  display: grid;
  grid-template-columns: auto auto auto;

  div {

    .anchorLess {
      color: inherit !important;
      text-decoration: none !important;
      display: flex;
      align-items: center;
      gap: 15px;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      text-align: center;
      padding: 10px;
      line-height: 1em;
      font-weight: var(--fontWeightRegular);
      font-size: 12px;

      img {
        width: 40px;
      }
    }
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu,
.mobileDrawer {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }

  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }

  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;

  @media (--viewportMedium) {
    padding: 21px 24px;
  }
}

.searchMenu {
  padding: 12px 0;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }
}

.mobileDrawer {
  padding: 12px 24px;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }
}

.mobileDrawer span {
  margin: 0 -6px;
  font-size: 20px;

  svg {
    fill: var(--colorGrey700);
  }
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 23px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}

.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 60px;
}

.eventsMenuContainer {
  position: relative;
  height: 100%;
  margin-top: 30px;
}

.searchBox {
  padding: 20px;
}

.formControl {
  @media (--viewportSmall) {
    border: solid 1px var(--colorLight);
    border-radius: 4px;
    padding: 10px;
  }

  div {
    padding: 0;
    margin: 0;
  }

  svg {
    display: none;
  }

  input {
    padding: 0;
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    height: 48px;
  }
}

.formLocation {
  margin-top: 15px;
  margin-bottom: 30px;
}

.formIcon {
  position: absolute;
  margin-top: 15px;
  z-index: 1;
  left: 30px;
}

.primaryButton {
  composes: buttonPrimary from global;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  line-height: 20px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}

.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;

  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}