@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

.serviceLink {}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  max-width: 200px;
  margin-left: 10px;
  margin-right: 10px;
}

/* Search */
.searchLink {
  height: 100%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 20px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 20px !important;
    margin-top: 27px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpBNEFDNzhCNTE5NTZFRjExOTJBNkIyMjU0MUMwODg3QiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGNkJFMjAyMTYwNjExMUVGODRENEI5RTVBQUQ4QjZDQyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGNkJFMjAyMDYwNjExMUVGODRENEI5RTVBQUQ4QjZDQyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1LjEgV2luZG93cyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBGRjE0Nzg5QzI1RUVGMTE4RkMzOTdFMThENTgxQjRBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE0QUM3OEI1MTk1NkVGMTE5MkE2QjIyNTQxQzA4ODdCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+LdCNHQAAAR9JREFUeNpi/P//PwOxILuqmwlI+QHxPRYiNXAAqTggLgZiNSAOYCGggQ9IJQJxCRBfAOIVQBwBxJuZcGhQAOIOIPMOEBsDsfvUtlJfIG0IxL1A9j8WNA36UOd4A/ESIDYCKnoClVMHUqZAHA7is0AFbYBUOdT0WUCsDNTwAc0hZUA8BSj+HcRhzKrsSgDS86GS54H4ORC/BuI3QLwYqPAi0GBJIPsKEKsC+e9ACpmAjAVAmgeIFYE4E4inA/EBkCIgjoEamAdyOkwT2EZc8Qi0BRSa9kCcC8T3gdgMqPEeTJ4JT2y8AmJRIE4D4p3ImuCBgwOA/CkFdWYguiQTAY0GQHwbaNs5UjS+gtJd2CRxagTa8hVIxQPxbmzyAAEGAKVnVyhIHupCAAAAAElFTkSuQmCC) no-repeat left center;
  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorBlack);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-radius: 0;
    text-decoration: none;

    .textLogin {
      color: var(--marketplaceColorDark);

    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarLinkLabel {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  align-items: center;
  text-decoration: inherit;
  position: relative;
}

.navIcon {
  font-size: 25px;
}

.topbarLogin {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--colorGrey);
}

.textLogin {
  font-size: 14px;
  color: #000;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

.notificationDotIn {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 20px;
  right: 180px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: #000;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
  text-align: left;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  .userSalute {
    color: var(--colorGrey);
    display: block;
    font-size: 12px;
  }

  .userIcon {
    font-size: 25px;
    color: #000;
    margin-right: 5px;
  }

  .navAvatar {
    background: #f0f0f0;
    color: #000;
  }
}


.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 300px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  border: 0px;
  cursor: pointer;

  /* Dimensions */
  width: 100%;
  min-width: 300px;
  margin: 0;
  padding: 15px 24px;

  /* Layout details */
  color: var(--colorGrey);
  text-align: left;
  transition: var(--transitionStyleButton);
  border-bottom: solid 1px var(--colorLight);

  .menuArrow {
    margin-left: auto;
  }

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: flex;
  align-items: center;
  gap: 16px;
  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 300px;
  margin: 0;
  padding: 15px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;

  .menuArrow {
    margin-left: auto;
  }

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--marketplaceColor);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--marketplaceColor);
    }
  }
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
  padding-left: 30px;
}

.serviceContainer {
  padding: 15px;
  border-radius: 24px;
  width: 380px; 
  justify-content: center;
  outline: solid 5px #ffffff40;
  box-shadow: var(--boxShadowPopup);
  margin-top: 5px;
  z-index: 9999;
}

.serviceContainer ul {
  display: grid;
  grid-template-columns: auto auto auto; 
}

.serviceContainer li {
  text-align: center;
  border-radius: 10px;
  margin-bottom: 15px;
  &:hover {
    background-color: #f1f1f1 !important;
  }
}

.serviceContainer img {
  max-width: 40px;
}

.drawerBox .logoLink {
  padding-left: 0;
}

.drawerBtn {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  padding: 0;
  display: flex;
  gap: 0;
  font-size: 27px;
  border-color: #f0f0f0;

  svg {
    margin: 0 -9px;
  }

  &:hover {
    background-color: #f1f1f1 !important;
    border-color: #f1f1f1 !important;
    color: rgba(0, 0, 0, 0.88) !important;
  }
}

.anchorLess {
  color: inherit !important;
  text-decoration: none !important;
  border: 0px;
  cursor: pointer;
  margin: 0 auto;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;
  line-height: normal;


}

.anchorSpan {
  display: block;
  margin: 5px auto;
  text-align: center;

  /* &:hover {
    background-color: var(--marketplaceColor) !important;

    & img {
      filter: brightness(0) invert(1);
    }
  } */

}