@import '../../../../styles/customMediaQueries.css';

.root {}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accountLinksWrapper,
.customLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.accountLinksWrapper {
  margin-bottom: 36px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 15px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  gap: 15px;
}

.contentGreeting {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 50px 0;
}

.avatar {
  /* Layout */
  flex-shrink: 0;
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 21px;

  padding: 3px 0 3px 0;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  color: var(--colorGrey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px var(--colorLight);

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }

  span {

    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.inbox {}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  color: var(--colorGrey);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
  border: 0px;
}

.exploreLink {
  /* Font */

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonSecondary from global;
  border-color: var(--colorBrand);
  color: var(--colorBrand);
  border-radius: 50px;
}

.createNewListingBtn {
  composes: buttonSecondary from global;
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border-radius: 50px;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 48px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

}

.spacer {
  width: 100%;
  height: 124px;
}

.loginbg {
  width: 310px;
  height: 310px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: transparent;
    border: 26px solid #f1f2f4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 6px 6px 18px 0 #090e1d33, 6px 6px 18px 0 #3754aa33 inset, -6px -6px 18px #fff, -6px -6px 18px #fff inset, 6px 6px 13px #fff inset;
    filter: blur(13px);
    animation: ripple 3s infinite ease-in;
  }

  .ripple2 {
    width: 150%;
    height: 150%;
  }

  .ripple3 {
    width: 200%;
    height: 200%;
  }

}

.logologin {
  max-width: 100px;
}

.loginimg {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btnFooter {
  position: absolute;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%);
  bottom: 50px;
  width: 100%;
  left: 0;
  padding: 20px;

  h4 {
    color: #2a2a2a;
    font-size: 40px;
    margin-bottom: 10px;
    line-height: 1em;
    text-align: center;

    span {
      color: var(--marketplaceColor);
    }
  }

  p {
    color: #2a2a2a;
    text-align: center;
  }
}

.loginbtn {
  composes: buttonPrimary from global;
  margin-bottom: 15px;
}

.signupbtn {
  composes: buttonPrimary from global;
  background: #222;
  box-shadow: none;
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }

  5% {
    opacity: 1;
  }
}


.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 23px;
  }
}

.notificationDott {
  background-color: red;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: -14px 0px 0px -15px;
}